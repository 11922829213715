import React, { useEffect } from 'react';
import { getUser } from '../context/global';

export default function SeloReclameAqui() {
  console.log('SeloReclameAqui desabilitado');
  return null;
}

function SeloReclameAqui_() {
  useEffect(() => {
    const user = getUser();
    const show = (user && user.id % 2 === 0) || false;
    if (show) {
      loadScript();
    }
  }, []);

  function loadScript() {
    if (document.getElementById('ra-embed-reputation')) {
      document.getElementById('ra-embed-reputation').remove();
    }

    const script = document.createElement('script');
    script.id = 'ra-embed-reputation';
    script.async = true;
    script.defer = true;
    script.src = 'https://s3.amazonaws.com/raichu-beta/selos/bundle.js';
    script.dataset.id = 'dzROODhmVE82SzBjcTJweDpmYXN0Zm9ybWF0';
    script.dataset.target = 'reputation-ra';
    script.dataset.model = '2';
    script.onload = () => {};
    document.body.appendChild(script);
  }

  /**
   * @param {Event} e
   */
  function removeLinkOnMouseEnter(e) {
    if (!e.target || !e.target.querySelector) {
      return;
    }

    /**@type {HTMLAnchorElement} */
    const link = e.target.querySelector('a');
    if (link) {
      link.href = 'javascript:void(0)';
      link.target = '';
    }
  }

  return <div id="reputation-ra" style={{ width: 'min-content' }} onMouseEnter={removeLinkOnMouseEnter} />;
}
